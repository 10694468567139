
<!-- <header>

  <app-header></app-header>
</header>

<main>

  <router-outlet></router-outlet>
</main> -->
<div class="row">

  <div class="col-md-6">
<div class="p-5">

  <img width="100%" src="../assets/IFrequencies_only.png" alt="">

</div>
  </div>

  <div class="col-md-6">

    <section class="mt-5">

      <div class="main-content mt-5">
      <h1 class="title customPrimaryColor mt-5"><b>Under Construction</b></h1>
      <p class="customPrimaryColor">Our website is currently undergoing scheduled maintenance.
      We Should be back shortly. Thank you for your patience.</p>


      <div class="mt-1 mt-md-5">
        <div class="card-body text-center h-100 customPrimaryColor">

          <h3 class="my-4 pb-2">Contact information</h3>
          <ul class="text-lg-left list-unstyled ml-4">
            <li>
              <p>
                <mdb-icon fas icon="map-marked" class="pr-2"></mdb-icon><span class="hover-text">6th floor Venue Star - Business Center</span>
              </p>
            </li>
            <li>
              <p>
                <mdb-icon fas icon="envelope-square" class="pr-2"></mdb-icon><span class="hover-text">454485 Dubai - UAE</span>
              </p>
            </li>
            <li>
              <p>

                <a class="hover-text" href="tel:+971506106191"><mdb-icon fas icon="phone" class="pr-2"></mdb-icon>+ 971 50 610 6191</a>
              </p>

            </li>
            <li>
              <p>

                <a class="hover-text" href="mailto:info@ifrequencies.com"><mdb-icon fas icon="envelope" class="pr-2"></mdb-icon>info@ifrequencies.com</a>
              </p>
            </li>
          </ul>
          <hr class="hr-light my-4">
          <ul class="list-inline text-center list-unstyled">
            <li class="list-inline-item">
              <a class="p-2 fa-lg fb-ic"
                href="https://www.facebook.com/Ifrequencies-102009834537484/?modal=admin_todo_tour" target="_blank">
                <mdb-icon fab icon="facebook-f"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="p-2 fa-lg ins-ic" href="https://www.instagram.com/i.frequencies/?hl=en" target="_blank">
                <mdb-icon fab icon="instagram"> </mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="p-2 fa-lg li-ic" href="https://www.linkedin.com/in/i-frequencies-187538194/" target="_blank">
                <mdb-icon fab icon="linkedin-in"> </mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="p-2 fa-lg tw-ic" href="https://twitter.com/ifrequencies1/" target="_blank">
                <mdb-icon fab icon="twitter"></mdb-icon>
              </a>
            </li>

          </ul>
          <!-- <a mdbBtn rounded="true" mdbWavesEffect class="customPrimarybackgorund button-effect" (click)="onSubscribe()">
            <mdb-icon fas icon="user" class="left"></mdb-icon>Subscribe
          </a> -->
        </div>

      </div>

    </div>

      </section>

  </div>

</div>

